@use "@angular/material" as mat;
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

/* Facilis blue is #006f88 */
/* Matching orange: #F69333 (darker than amber)
  In-between orange: #FBAA1D
  Material Design amber: #FFC107 (more flashy)
  https://coolors.co/f69333-fbaa1d-ffc107-006f88-51a3bd-8acede-c3f9ff-e6f4f1
*/

$facilis-blue-palette: (
  50: #f0f6f8,
  100: #d0e4ea,
  // between 100 and 200: ADD9E4
  200: #8acede,
  300: #51a3bd,
  400: #006f88,
  500: #006f88,
  600: #006f88,
  700: #00526b,
  800: #00526b,
  900: #00445b,
  A100: #4b9db8,
  A200: #4b9db8,
  A400: #4b9db8,
  A700: #4b9db8,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: rgba(white, 0.87),
    800: rgba(white, 0.87),
    900: rgba(white, 0.87),
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  ),
);

$custom-theme-primary: mat.m2-define-palette($facilis-blue-palette);
$custom-theme-accent: mat.m2-define-palette(mat.$m2-amber-palette);
$custom-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);
// $custom-theme: mat.define-light-theme($custom-theme-primary, $custom-theme-accent, $custom-theme-warn);
$custom-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $custom-theme-primary,
      accent: $custom-theme-accent,
      warn: $custom-theme-warn,
    ),
    typography:
      mat.m2-define-typography-config(
        $button: mat.m2-define-typography-level(14px, 1.125, 500, null, 0px),
        $caption: mat.m2-define-typography-level(10px, 1.38, 400)
      ),
    density: 0,
  )
);
@include mat.all-component-themes($custom-theme);
// @include mat.all-component-themes($custom-theme);

$router-theme-primary: mat.m2-define-palette($facilis-blue-palette);
$router-theme-accent: mat.m2-define-palette(mat.$m2-amber-palette);
$router-theme-warn: mat.m2-define-palette(mat.$m2-red-palette);
$router-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $router-theme-primary,
      accent: $router-theme-accent,
      warn: $router-theme-warn,
    ),
    density: 0,
  )
);
//@include mat.all-component-themes($router-theme);

/* For Angular MDC */
/*$mdc-theme-primary: #006f88;
$mdc-theme-secondary: mat-color($custom-theme-accent);

@import "@angular-mdc/theme/material";
*/

.mdc-text-field--filled .mdc-floating-label,
.mdc-text-field--outlined .mdc-floating-label {
  font-size: 13px !important;
}

.mat-mdc-form-field {
  font-size: 13px; // Applies to the entire form field
}

.mat-mdc-text-field-wrapper {
  font-size: 13px; // Applies specifically to input fields
}

.mat-mdc-select-value {
  font-size: 13px; // Applies specifically to mat-select
}

.mat-mdc-card-actions button:not(:last-child) {
  margin-right: 8px; // Adjust as needed
}

.mat-mdc-form-field-hint {
  display: flex;
}

@include mat.form-field-density(-1);

// Workaround to apply mat-elevation-z{x} class. Issue related https://github.com/angular/components/issues/26094
@for $i from 0 through 24 {
  .mat-mdc-card.mat-elevation-z#{$i} {
    @include mat.elevation($i);
  }
}
