/* You can add global styles to this file, and also import other style files */
@import './app/loader/loader.component.css';
body {
  font-family: Roboto, Arial, sans-serif;
  margin: 0;
}

.example-form {
  width: 100%;
}

.example-full-width {
  width: 100%;
}

.copyright {
  font-size: 8pt;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.grid {
  display: grid;
}

[dense] .material-icons {
  font-size: 14px;
}

/* == Timeline Styling ============== */

.vis-item.vis-background.airac {
  background-color: rgba(255, 0, 0, 0.2);
}

.vis-timeline {
  font-size: 12px;
}

.vis-item {
  border-color: #006f88;
  background-color: #d4dfe7;
}

.airac {
  background-color: #FFCCCC;
}

/*
.permanent {
  background-color: white;
  background-image: linear-gradient(to right, rgba(75, 157, 184, 1), rgba(75, 157, 184, 0));
}
*/

.vis-item.vis-selected {
  border-color: #ffc107;
}

.vis-item .vis-item-overflow {
  overflow: visible;
}

.mat-card {
  min-height: 100% !important;
}

.mat-icon.disable {
  color: gray;
  pointer-events: none;
  cursor: default;
}

.mat-icon.enable {
  cursor: pointer;
}